import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '../Link';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  projectNum: {
    mixBlendMode: 'hard-light',
    opacity: '50%',
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    marginTop: 15,
    fontFamily: 'Roboto Condensed',
  },
  photo: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    textAlign: 'center',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
}));

export default function SelectedWork() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={6}>
        <Box position="relative" top={38}>
          <Typography variant="h1" className={classes.projectNum}>
            01
          </Typography>
        </Box>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardActionArea>
              <Link to="/opus" underline="none">
                <CardHeader
                  title="OPUS"
                  subheader="Web Development"
                  className={classes.margin}
                />
                <CardMedia
                  className={classes.photo}
                  image="/static/Opus.png"
                  title="Opus Piano App screenshot"
                />
                <CardContent className={classes.content}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    An online piano keyboard complete with visualizations and
                    lessons to help any beginner master piano skills. Built in a
                    15-day sprint with a 4-member team.
                  </Typography>
                </CardContent>
              </Link>
            </CardActionArea>
            <CardActions className={classes.controls}>
              <Button
                size="medium"
                color="primary"
                href="http://opuslesson.com"
                target="blank"
                rel="noopener"
              >
                DEMO
              </Button>
              <Button size="medium" color="primary">
                <Link to="/opus">LEARN MORE</Link>
              </Button>
            </CardActions>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box position="relative" top={38}>
          <Typography variant="h1" className={classes.projectNum}>
            02
          </Typography>
        </Box>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardActionArea>
              <Link to="/weatherd" underline="none">
                <CardHeader
                  title="WEATHER*D"
                  subheader="Web Development"
                  className={classes.margin}
                />
                <CardMedia
                  className={classes.photo}
                  image="/static/Weatherd.png"
                  title="Weather*d app screenshot"
                />
                <CardContent className={classes.content}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Application that forecasts current and future weather
                    conditions based on a searched location. Uses OpenWeatherMap
                    and Mapbox APIs. Sole developer; built in 3 days.
                  </Typography>
                </CardContent>
              </Link>
            </CardActionArea>
            <CardActions className={classes.controls}>
              <Button
                size="medium"
                color="primary"
                href="http://jennmez.github.io/weather/"
                target="blank"
                rel="noopener"
              >
                DEMO
              </Button>
              <Button size="medium" color="primary">
                <Link to="/weatherd">LEARN MORE</Link>
              </Button>
            </CardActions>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box position="relative" top={38}>
          <Typography variant="h1" className={classes.projectNum}>
            03
          </Typography>
        </Box>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardActionArea>
              <Link to="/pickles" underline="none">
                <CardHeader
                  title="GRACED PICKLES"
                  subheader="Web Development"
                  className={classes.margin}
                />
                <CardMedia
                  className={classes.photo}
                  image="/static/Pickles.png"
                  title="Graced Pickles screenshot"
                />
                <CardContent className={classes.content}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    MVP e-Commerce marketplace for buying one-of-a-kind pickles.
                    Uses a persistent cart so users won't lose their purchases.
                    Built in a 10-day sprint with a 3-person team.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  ></Typography>
                </CardContent>
              </Link>
            </CardActionArea>
            <CardActions className={classes.controls}>
              <Button
                size="medium"
                color="primary"
                href="http://www.gracedpickles.com/"
                target="blank"
                rel="noopener"
              >
                DEMO
              </Button>
              <Button size="medium" color="primary">
                <Link to="/pickles">LEARN MORE</Link>
              </Button>
            </CardActions>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box position="relative" top={38}>
          <Typography variant="h1" className={classes.projectNum}>
            04
          </Typography>
        </Box>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardActionArea>
              <Link
                component="a"
                href="https://www.github.com/jennmez"
                target="blank"
                rel="noopener"
                underline="none"
              >
                <CardHeader
                  title="FUTURE WORK"
                  subheader="TBD"
                  className={classes.margin}
                />
                <CardMedia
                  className={classes.photo}
                  image="/static/Code.png"
                  title="VS Code screenshot"
                />
                <CardContent className={classes.content}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    Exploration and practice is the key to growth, so stay tuned
                    for new projects! Feel free to checkout my Github account
                    for a sneak peek at what I have have coming down the
                    pipeline.
                  </Typography>
                </CardContent>
              </Link>
            </CardActionArea>
            <CardActions className={classes.controls}>
              <Button
                size="medium"
                color="primary"
                href="http://www.github.com/jennmez"
                target="blank"
                rel="noopener"
              >
                GITHUB
              </Button>
            </CardActions>
          </div>
        </Card>
      </Grid>
      {/* COMMENTED OUT UNTIL MORE WORK IS ADDED
       <Grid item xs={12}>
        <Button color="secondary" size="large">
          <Box component="span" p={1}>
            MORE WORK
          </Box>
          <FontAwesomeIcon
            icon={faLongArrowAltRight}
            size="2x"
          ></FontAwesomeIcon>
        </Button>
      </Grid> */}
    </Grid>
  );
}
